module.exports = [{
      plugin: require('../node_modules/@mobile-reality/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleConsent":{"analyticsStorage":"level-analytics-ga-consent","waitForUpdate":500},"googleAnalytics":{"trackingId":"G-FHJRGSGRNJ","cookieName":"level-analytics-ga","anonymize":true,"allowAdFeatures":false},"googleTag":{"trackingIds":["G-FHJRGSGRNJ"],"cookieName":"level-analytics-gt"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Level - AI Assistant","short_name":"Level","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f2702c04ae2f861e658d01b9dade6fd4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
